// data.js

const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
    { item: 4, code: "Fiziki şəxs" },
    { item: 5, code: "İctimai Birlik" },
    { item: 6, code: "Digər" },
    { item: 7, code: "Filial" },
    { item: 8, code: "Nümayəndəlik" },
  ];
  
  const socialMedia = [
    { title: "Facebook", name: "social1" },
    { title: "Twitter", name: "social2" },
    { title: "Linkedin", name: "social3" },
    { title: "Youtube", name: "social4" },
    { title: "Wikipedia", name: "social5" },
  ];
  
  const socialMediaCeo = [
    { title: "Facebook", name: "ceosocial1" },
    { title: "Twitter", name: "ceosocial2" },
    { title: "Linkedin", name: "ceosocial3" },
    { title: "Youtube", name: "ceosocial4" },
    { title: "Wikipedia", name: "ceosocial5" },
  ];
  
  export { items, socialMedia, socialMediaCeo };
  