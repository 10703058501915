import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Tooltip,
  Input,
  Popconfirm,
  Upload,
  Select,
} from "antd";

import { notify } from "../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../utils/rules";
import axios from "axios";
function TechnologySubcategory(props) {
  const mainUrl = "TechnologySubcategory";
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const nameInput = useRef();
  const { Option } = Select;
  const [categories, setCategoires] = useState([]);

  const setEditingObject = async (i) => {
    await aictia.get(mainUrl + `/${props.id}`).then((res) => {
  
      let obj = {};
      obj[`name`] = res.data.name;
      obj[`technologyCategoryId`] =res?.data?.technologyCategoryId;

      form.setFieldsValue(obj);
    });
  };

  const cancelEditing = () => {
    props.setEditing(null);
    form.resetFields();
  };

  const savePosition = async (values) => {
    let obj={
        ...values
    }
 
    if (!props.id) {
      await aictia
        .post(mainUrl, obj)
        .then((res) => {
          notify("", true);
          props.getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    } else {
        obj["id"] =props.id
      await aictia
        .put(`${mainUrl}/${props.id}`, obj)
        .then((res) => {
          notify("", true);
          props.getPositions();
          cancelEditing();
        })
        .catch((err) => {
          notify(err.response, false);
        });
    }
  };

  const getCategories = async () => {
    await aictia.get("Technology").then((res) => {
      setCategoires(res?.data);
    });
  };
  console.log(categories);
  useEffect(() => {
    setEditingObject();
    getCategories();
  }, [props.id]);
  return (
    <div>
      {" "}
      <Card title={"Kateqoriya əlavə et"} className={"animated fadeInRight"}>
        <Form layout="vertical" onFinish={savePosition} form={form}>
          <div className="form-lang">
            <div className="mb-10">Texnologiyaların kateqoriyası</div>
            <Form.Item
              name={"technologyCategoryId"}
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Select>
                {categories.map((item, i) => {
                  return (
                    <Option key={i} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div className="mb-10">
              Texnologiyaların alt kateqoriyasının adı
            </div>
            <Form.Item
              name={"name"}
              validateTrigger="onChange"
              rules={[noWhitespace(t("inputError"))]}
            >
              <Input ref={nameInput} />
            </Form.Item>
          </div>

          <div className="flex  flex-between mt-15">
            <Button onClick={cancelEditing}>{t("cancel")}</Button>
            <Button htmlType="submit">{t("save")}</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default TechnologySubcategory;
