import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Tooltip,
  Input,
  Popconfirm,
  Upload,
  Select,
} from "antd";
import {
  UnorderedListOutlined,
  EditFilled,
  DeleteFilled,
  ToolFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../../../utils/rules";

import Technology from "../../../Technologies/technology";
import TechnologySubcategory from "../../../Technologies/technologySubcategory";
import TechnologyType from "../../../Technologies/technologyType";

const Technologies = (props) => {
  const mainUrl = "Auth/register";
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [positions, setPositions] = useState([]);
  const [spin, setSpin] = useState(false);
  const [editing, setEditing] = useState(null);
  const [reset, setReset] = useState(null);

  const [columns, setColumns] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Technology");

  const { notify } = props;
  const cols = [
    { key: "index", value: "#", con: true },
    { key: "userName", value: t("name"), con: true },
    { key: "image", value: "Şəkil", con: true },
    { key: "id", value: "", con: true },
  ];

  const defaultColumns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: t("name"),
      key: "2",
      dataIndex: "name",
    },
    {
      title: "",
      key: "3",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePosition(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Button
                className="border-none"
                type="text"
                shape="circle"
                onClick={() => setEditing(i)}
              >
                <EditFilled />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const categorySpecificColumns = {
    TechnologySubcategory: [
      {
        title: " kateqoriya",
        key: "4",
        dataIndex: "technologyCategory",
        render: (i) => {
          return <p>{i?.name}</p>;
        },
      },
    ],
    TechnologyType: [
      {
        title: "alt kateqoriya",
        key: "5",
        dataIndex: "technologySubcategory",
        render: (i) => {
          return <p>{i?.name}</p>;
        },
      },
      {
        title: " kateqoriya",
        key: "6",
        dataIndex: "technologyCategory",
        render: (i) => {
          return <p>{i?.name}</p>;
        },
      },
    ],
  };

  const { Option } = Select;

  const deletePosition = async (i) => {
    await aictia
      .delete(`${selectedCategory}/${i}`)
      .then(() => {
        // description
        notify("silindi", true);
        getPositions();
      })
      .catch((err) => {
        //error
        notify(err.response, false);
      });
  };

  const getPositions = async () => {
    setSpin(true);
    await aictia.get(selectedCategory).then((res) => {
      setSpin(false);
      setPositions(
        res.data.map((p, index) => {
          return {
            key: index + 1,
            ...p,
            index: index + 1,
          };
        })
      );
    });
  };
  const columnsfunc = () => {
    let newColumns = [...defaultColumns];
    if (selectedCategory && categorySpecificColumns[selectedCategory]) {
      const categoryColumns = categorySpecificColumns[selectedCategory];
      newColumns.splice(-1, 0, ...categoryColumns);
  
    }
    setColumns(newColumns);
  };
  useEffect(() => {
    getPositions();

    columnsfunc();
  }, [t, selectedCategory]);

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <div className="border animated fadeInDown p-2 mt-0 bg-white">
          <UnorderedListOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Texnologiyalar</span>
        </div>
      </Col>

      <Col xs={12}>
        <div className="form-lang">
          <Form.Item
            name={"Technologies"}
            validateTrigger="onChange"
            rules={[noWhitespace(t("inputError"))]}
          >
            <Select
              defaultValue="Texnologiyalar kateqoriyasının adı"
              onChange={(e) => setSelectedCategory(e)}
            >
              <Option key={1} value={"Technology"}>
                Texnologiyalar kateqoriyasının adı
              </Option>
              <Option key={2} value={"TechnologySubcategory"}>
                Texnologiyaların alt kateqoriyasının adı
              </Option>
              <Option key={3} value={"TechnologyType"}>
                Texnologiyaların növ adı
              </Option>
            </Select>
          </Form.Item>
        </div>
        <Col lg={24} xs={24}>
          <Table
            loading={spin}
            size="small"
            className="bg-white animated fadeInLeft"
            columns={columns}
            dataSource={convertColumns(positions, cols)}
            pagination={{
              pageSize: 10,
              current_page: 1,
              total: positions.length,
            }}
          />
        </Col>
      </Col>

      {selectedCategory === "Technology" && (
        <Col lg={12} xs={24}>
          <Technology
            id={editing}
            setEditing={setEditing}
            getPositions={getPositions}
          />
        </Col>
      )}
      {selectedCategory === "TechnologySubcategory" && (
        <Col lg={12} xs={24}>
          <TechnologySubcategory
            id={editing}
            setEditing={setEditing}
            getPositions={getPositions}
          />
        </Col>
      )}
      {selectedCategory === "TechnologyType" && (
        <Col lg={12} xs={24}>
          <TechnologyType
            id={editing}
            setEditing={setEditing}
            getPositions={getPositions}
          />
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

export default connect(mapStateToProps, { notify })(Technologies);
