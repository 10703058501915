import React, {useState} from 'react';
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
    AuditOutlined,
    UsergroupAddOutlined,
    BankOutlined,
    SwitcherOutlined,
    SnippetsOutlined,
    LaptopOutlined,
    AudioMutedOutlined,
    GatewayOutlined
  } from "@ant-design/icons";


function CollapseMenu() {
    const [openKeys, setOpenKeys] = useState([]);
    const rootSubmenuKey = ["11", "21", "31", "41", "51", "61", "71", "81", "91", "101"];
  
    const onOpenChange = (openKeysList) => {
        const latestOpenKey = openKeysList.find(
          (key) => openKeys.indexOf(key) === -1
        );
        if (rootSubmenuKey.indexOf(latestOpenKey) === -1) {
          setOpenKeys(openKeysList);
        } else {
          const opens = latestOpenKey ? [latestOpenKey] : [];
          setOpenKeys(opens);
        }
      };
    
  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      
 
      <Menu.Item key="11">
          <Link to={`/activity`}>
          <span>
            <AuditOutlined />
            <span>Fəaliyyət sahələri</span>
          </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="21">
          <Link to={`/admin/users`}>
          <span>
            <UsergroupAddOutlined />
            <span>İstifadəçilər</span>
          </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="31">
          <Link to={`/admin/stateorprivate`}>
          <span>
          <BankOutlined />
            <span >Dövlət/Özəl</span>
          </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="41">
          <Link to={`/admin/fieldOfUse`}>
          <span>
          <SnippetsOutlined />
            <span >İstifadə sahəsi</span>
          </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="51">
          <Link to={`/admin/types`}>
          <span>
          <SwitcherOutlined />
            <span >Növlər</span>
          </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="61">
          <Link to={`/admin/technologies`}>
          <span>
          <LaptopOutlined />
            <span >Texnologiyalar</span>
          </span>
          </Link>
        </Menu.Item>

        <Menu.Item key="71">
          <Link to={`/admin/sector`}>
          <span>
          <GatewayOutlined />
            <span >Sektor</span>
          </span>
          </Link>
        </Menu.Item>


    </Menu>
  )
}

export default CollapseMenu