import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  AuditOutlined,
  FileDoneOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  BarChartOutlined,
  SnippetsOutlined,
  SwitcherOutlined,
  LaptopOutlined,
  GatewayOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const MenuList = () => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([]);

  const rootSubmenuKeys = ["11", "21", "31", ];


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      
          <Menu.Item key="11">
            <Link to={`/register`}>
            <span>
            <FileDoneOutlined />
            <span >Reyester</span>
          </span>
             
            </Link>
          </Menu.Item>
          <Menu.Item key="21">
            <Link to={`/dashboard`}>
            <span>
            <BarChartOutlined />
            <span >Hesabat formaları</span>
          </span>
             
            </Link>
          </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = ({ user, stock }) => {
  return {
    // perms: user.data.userPermissions, stock 
  };
};

export default connect(mapStateToProps, {})(MenuList);


