import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Form,
  Tooltip,
  Input,
  Popconfirm,
  Upload,
  Select,
} from "antd";

import { notify } from "../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../utils/rules";
import axios from "axios";
function Technology(props) {
    const mainUrl = "Technology";
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const nameInput = useRef();

    const setEditingObject = async (i) => {
      
        await aictia.get(mainUrl + `/${props.id}`).then((res) => {
            const name = res.data.name
            let obj = {};
                obj[`name`] = name;
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        props.setEditing(null);
        form.resetFields();
    };


    const savePosition = async (values) => {;
        if (!props.id) {
            await aictia
                .post(mainUrl, values)
                .then((res) => {
                    notify("", true);
                    props.getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            await aictia
                .put(`${mainUrl}/${props.id}`, {id:props.id,name:values.name})
                .then((res) => {
                    notify("", true);
                    props.getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };
    useEffect(()=>{
        setEditingObject()
    },[props.id])
  return (
   
    <Card title={t("addTo")} className={"animated fadeInRight"}>
      <Form layout="vertical" onFinish={savePosition} form={form}>
        <p className="mb-5">Texnologiyaların kateqoriyasının adı</p>
        <div className="form-lang">
          <Form.Item
            className="mb-5"
            validateTrigger="onChange"
            name="name"
            rules={[whiteSpace(t("inputError"))]}
          >
            <Input ref={nameInput} />
          </Form.Item>
        </div>
        <div className="flex  flex-between mt-15">
          <Button onClick={cancelEditing}>{t("cancel")}</Button>
          <Button htmlType="submit">{t("save")}</Button>
        </div>
      </Form>
    </Card>

  )
}

export default Technology